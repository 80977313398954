import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store';  // 引入 Vuex store
import 'bootstrap-icons/font/bootstrap-icons.css';


const app = createApp(App);

// 在应用启动时调用 initializeStore
store.dispatch('initializeStore');

app.use(router) // 使用路由
   .use(store)  // 确保在这里使用 store
   .mount('#app');
