// src/store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    token: null,
    username: null,
    userID: null  // 新增 userID
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setUserID(state, userID) {  // 新增 setUserID
      state.userID = userID;
    },
    clearToken(state) {
      state.token = null;
      state.username = null;
      state.userID = null;  // 清空 userID
    }
  },
  actions: {
    login({ commit }, { token, username, userID }) {
      commit('setToken', token);
      commit('setUsername', username);
      commit('setUserID', userID);  // 在登录时设置 userID
    },
    logout({ commit }) {
      commit('setUsername', '');
      commit('setUserID', '');  
      commit('setToken', null);
      localStorage.removeItem('token'); 
      localStorage.removeItem('username'); 
      localStorage.removeItem('userID'); 
    },
    initializeStore({ commit }) {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
      const userID = localStorage.getItem('userID');
      if (token) {
        commit('setToken', token);
        commit('setUsername', username);
        commit('setUserID', userID);  // 在登录时设置 userID
      }
    }
  },

  getters: {
    token: state => state.token,
    username: state => state.username,
    userID: state => state.userID  // 新增 userID getter
  }
});

export default store;
