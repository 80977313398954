<template>
    <div class='Title'>
        <img class="LOGO" src="../assets/image.png" alt="Description of the image">
        <nav class="router-l">
            <router-link to="/home">工作台</router-link>
            <router-link to="/quicklyStart">入门文档</router-link>
            <router-link to="/aboutUs">关于我们</router-link>
        </nav>
        <div class="User">
            <div class="UserCard" @click="toggleMenu('Card')">
                <img class="UserImg"
                    src="https://img.js.design/assets/img/66c54ae653d3d671728fe6c6.png#df3ffc78c38a41ba0175deed779db45a"
                    alt="Description of the image">
                <div class="UserName">{{ username }}&nbsp;&nbsp;</div>
            </div>
            <div v-if="isCardOpen" class="popup-content">
                <div class="icon" @click="logout">
                    <i class="bi bi-box-arrow-right"></i>
                    &nbsp;&nbsp;&nbsp;退出
                </div>
            </div>

        </div>

    </div>
    <!-- 二级路由出口：二级组件展示的位置 -->
    <router-view></router-view>

</template>

<script>


export default {
    name: 'LayoutIndex',
    components: {
    },
    computed: {
        username() {
            return this.$store.getters.username;
        }
    },
    data() {
        return {
            isCardOpen: false,
        };
    },
    methods: {
        toggleMenu(menu) {
            console.log(`Toggling menu: ${menu}`); // 调试用
            // 切换弹出层的显示状态
            this[`is${menu}Open`] = !this[`is${menu}Open`];
        },
        logout() {
            this.$store.dispatch('logout'); // 调用 Vuex 中的注销操作
            this.$router.push('/login'); // 重定向到登录页面
        },
    },

}
</script>

<style>
.Title {
    display: flex;
    height: 74px;
    background-color: #006d95;
    width: 100%;
}

.Title .LOGO {
    display: flex;
    margin-left: 267px;
    margin-top: 20px;
    width: 197px;
    height: 43px;
}

.router-l {
    display: flex;
    margin-top: 43px;
    margin-left: 100px;
    text-decoration: none;
    flex-wrap: nowrap;
    /* 禁止换行 */
}

.router-l a {
    display: flex;
    margin-left: 20px;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    white-space: nowrap;
    /* 防止文字换行 */
    font-size: 18px;
}


.router-l a:hover {
    color: aqua;
    /* 悬停颜色 */
}

.router-link-active {
    color: aqua !important;
    /* 当前激活链接的颜色 */
}

.User {
    position: relative;
    /* 确保 popup-content 以 User 为基准定位 */
    display: flex;
    margin-top: 25px;
    margin-left: auto;
    margin-right: 10%;
}


.UserCard {
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;

}

.UserCard:hover {
    background-color: #0598ce;
}

.UserImg {
    display: flex;
    float: left;
    width: 38px;
    height: 38px;
    border-radius: 50%;
}

.UserName {
    display: flex;
    margin-left: 10px;
    margin-top: 10px;
    width: auto;
    color: white;
    font-size: 18px;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: absolute;
    top: 100%;
    /* 使弹出层出现在 UserCard 下方 */
    left: 0;
    width: 80px;
    /* 根据需要调整宽度 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 18px;
}

.icon {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    height: 40px;
    width: 80px;
    border-radius: 8px;
}

.icon:hover {
    background-color: aliceblue;
}
</style>