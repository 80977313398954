<template>
  <div class="prompt">
    <span class="prompt-title">绘画</span>
    <div class="prompt-content" ref="promptContent">
      我想画张图，主题是
      <div class="userinput" contenteditable="true"
        style="display:inline-block; min-width:100px; padding:2px; border:1px solid #ccc;">
        输入主题
      </div>
      ，要求
      <div class="userinput" contenteditable="true"
        style="display:inline-block; min-width:100px; padding:2px; border:1px solid #ccc;">
        输入细节
      </div>
      ，风格为
      <select id="style">
        <option value="anime">动漫</option>
        <option value="realistic">写实</option>
        <option value="cartoon">卡通</option>
      </select>
      ，比例为
      <select id="ratio">
        <option value="16:9">16:9</option>
        <option value="4:3">4:3</option>
        <option value="1:1">1:1</option>
      </select>。
    </div>
    <button class="prompt-btn" @click="copyToClipboard">点击复制</button>
  </div>
</template>

<script>
export default {
  name: 'DrawPromptComponent',
  methods: {
    async copyToClipboard() {
      const promptContent = this.$refs.promptContent;

      // 克隆内容并替换 select 的选项文本
      const clonedContent = promptContent.cloneNode(true);
      const styleSelect = clonedContent.querySelector('#style');
      const ratioSelect = clonedContent.querySelector('#ratio');

      if (styleSelect) {
        styleSelect.replaceWith(document.createTextNode(styleSelect.options[styleSelect.selectedIndex].text));
      }
      if (ratioSelect) {
        ratioSelect.replaceWith(document.createTextNode(ratioSelect.options[ratioSelect.selectedIndex].text));
      }

      // 创建一个临时的文本区域来存放要复制的内容
      const textarea = document.createElement('textarea');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      textarea.value = clonedContent.textContent;
      document.body.appendChild(textarea);
      textarea.select();

      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(textarea.value);
          console.log('内容已成功复制');
        } else {
          document.execCommand('copy');
          console.log('内容已成功复制（使用 document.execCommand）');
        }
      } catch (err) {
        console.error('复制失败:', err);
      } finally {
        document.body.removeChild(textarea); // 清理临时文本区域
      }
    }
  }
}

</script>




<style scoped>
.prompt {
  display: flex;
  flex-direction: column;
  /* 确保子元素垂直排列 */
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  width: 320px;
  margin-bottom: 20px;
}

.prompt-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
  /* 增加间距以分隔标题和内容 */
}

.prompt-content {
  display: block;
  /* 确保内容是块级元素，可以换行 */
  font-size: 17px;
  margin-bottom: 10px;
}

.userinput {
  display: inline-block;
  padding: 2px;
  border: 1px solid #9bc0dd !important;
  min-width: 50px !important;
  max-width: 100%;
  /* 设置一个最大宽度，避免过长 */
  background-color: #f9f9f9;
  /* 改变背景颜色 */
  border-radius: 4px;
  /* 圆角边框 */
  font-size: 16px;
  
}



select {
  background: #fafdfe;
  height: 28px;
  width: auto;
  line-height: 28px;
  border: 1px solid #9bc0dd;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-size: 16px;
}

.prompt-btn {
  font-size: 14px;
  color: white;
  border-radius: 5px;
  background: rgba(113, 195, 240, 1);
  height: 29px;
  border: none;
  /* 去除边框 */
}

/* 鼠标悬停时的样式 */
.prompt-btn:hover {
  background: rgba(85, 155, 201, 1);
  /* 改变背景颜色 */
}

/* 点击按钮时的样式 */
.prompt-btn:active {
  background: rgba(87, 164, 216, 1);
  /* 改变背景颜色 */
  transform: scale(0.98);
  /* 轻微缩小按钮以模拟按下效果 */
}
</style>