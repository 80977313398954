<template>
  <div class="home">
    <div class="botlist">
      <BotListComponent />
    </div>

    <div class="hello" v-if="hello_vue">

      <HelloComponent />
    </div>

    <div class="Bot" v-if="botInfo">
      <!-- 直接渲染 id 和 title -->
      <!-- <div class="bot-info">
        <div>ID: {{ botInfo.id }}</div>
        <div>Title: {{ botInfo.title }}</div>
      </div> -->
      <div id="position_demo"></div>
    </div>

    <div class="promptlist" v-if="!hello_vue">
      <PromptListComponent />
    </div>

    <div class="promptlist" v-if="hello_vue">
      <AnnouncementListComponent></AnnouncementListComponent>
    </div>


  </div>
</template>

<script>
import BotListComponent from '@/components/Web_BotList.vue';
import PromptListComponent from '@/components/Web_PromptList.vue';
import HelloComponent from '@/components/Web_Hello.vue';
import AnnouncementListComponent from '@/components/Web_AnnouncementList.vue';
import emitter from '@/utils/EventBus';
import { ref, onMounted, onUnmounted, watch } from 'vue';

export default {
  name: 'HomePage',
  components: {
    BotListComponent,
    PromptListComponent,
    HelloComponent,
    AnnouncementListComponent,
  },

  setup() {
    const hello_vue = ref(true);
    const botInfo = ref({
      id: '7410255540825128997',
      url: 'https://s2.loli.net/2024/08/30/ixK2stm9O8pvegM.jpg',
      title: '设计助理Mia'
    });
    let chatClient = null;
    let scriptLoaded = false;
    const handleHelloMessage = (msg) => {
      hello_vue.value = msg === 'true';
    };

    const handleBotInfo = (id, url, title) => {
      botInfo.value = { id, url, title };
    };

    const initializeChat = () => {
      if (botInfo.value) {
        const { id: bot_id, url: icon, title } = botInfo.value;
        const width = 1200;

        if (chatClient) {
          chatClient.destroy(); // 清理上一次的聊天实例
        }

        if (!scriptLoaded) {
          const script = document.createElement('script');
          script.src = 'https://lf-cdn.coze.cn/obj/unpkg/flow-platform/chat-app-sdk/0.1.0-beta.5/libs/cn/index.js';
          script.onload = () => {
            scriptLoaded = true;
            chatClient = new CozeWebSDK.WebChatClient({
              config: {
                bot_id,
              },
              componentProps: {
                title,
                icon,
                width,
              },
              el: document.getElementById('position_demo'),
            });
          };
          document.head.appendChild(script);
        } else {
          chatClient = new CozeWebSDK.WebChatClient({
            config: {
              bot_id,
            },
            componentProps: {
              title,
              icon,
              width,
            },
            el: document.getElementById('position_demo'),
          });
        }
      }
    };

    onMounted(() => {
      // 立即执行聊天初始化
      initializeChat();

      // 监听事件

      emitter.on('sendHello', handleHelloMessage);
      emitter.on('sendId', (id) => {
        emitter.on('sendImageUrl', (url) => {
          emitter.on('sendTitle', (title) => {
            handleBotInfo(id, url, title);
          });
        });
      });
    });

    onUnmounted(() => {
      if (chatClient) {
        chatClient.destroy();
      }
      emitter.off('sendHello', handleHelloMessage);
      emitter.off('sendId');
      emitter.off('sendImageUrl');
      emitter.off('sendTitle');
    });

    watch(botInfo, (newBotInfo) => {
      if (newBotInfo) {
        initializeChat(); // botInfo 更新后，执行初始化

        console.log("完成初始化");
      }
    });

    return { hello_vue, botInfo };
  },
};

</script>


<style scoped>
.home {
  display: flex;
  width: 100%;
  /* 确保 `.home` 占据父容器的全部宽度 */
  height: 100%;
}

.botlist {
  /* 没有额外的样式 */
  width: 20%;
}

.hello {

  flex-grow: 0.5;
  /* 确保中间部分占据剩余空间 */
  margin: 1.6%;
  height: 900px;
}

.promptlist {
  padding-top: 1%;
  width: 20%;
  margin-left: auto;
  /* 将 `.promptlist` 推到右边 */
  margin-right: 2%;
  /* 右边距 */
}

#position_demo {
  position: fixed;
  overflow: hidden;
  width: 0px;
  height: 0px;
}
</style>