<template>
    <div>
        <nav class="offenBot">
            <div class="offenBot-item">
                <div class="offenBot-title" @click="toggleMenu('home')"> 
                    <i class="bi bi-image"></i>
                    <span class="recommend">图像处理</span>
                    <i class="caret" :class="{ 'bi-caret-left': !isHomeOpen, 'bi-caret-down': isHomeOpen }"></i>
                </div>
                <div class="offen_bots" v-if="isHomeOpen">
                    <MiaComponent  :key="2"></MiaComponent>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>

import MiaComponent from './Coze_Mia.vue';

export default {
    name: 'ImageProcessingComponent',
    components: {
        MiaComponent,
    },
    props: {
    },
    data() {
        return {
            isHomeOpen: false,
        };
    },
    methods: {
        toggleMenu(menu) {
            this[`is${menu.charAt(0).toUpperCase() + menu.slice(1)}Open`] =
                !this[`is${menu.charAt(0).toUpperCase() + menu.slice(1)}Open`];
        },
    },
};

</script>


<style scoped>
.offenBot {
    display: flex;
    flex-direction: column;
    /* 使导航栏竖直排列 */
    background: rgba(240, 240, 240, 1);
    padding: 10px;
    width: 260px;
    margin-left: 20px;
}

.offenBot-item {
    display: flex;
    flex-wrap: wrap; /* 允许子元素换行 */
    color: black;
    padding: 10px;
    cursor: pointer;
}

.offenBot-title {
    display: flex;
    width: 260px;
    align-items: center; /* 垂直居中 */
}

i {
    font-size: 24px;
}

.recommend {
    margin-left: 20px;
    font-size: 18px;
}

.caret {
    margin-left: auto; 
}

.offenBot-title:hover {
    background-color:white;
}

.offen_bots{
    flex-basis: 100%;
}


</style>