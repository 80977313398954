<template>
  <div class="login-container">
    <div class="login-box">
      <div class="avatar"></div>
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <label for="username">用户名</label>
          <input type="text" id="username" v-model="username" required />
        </div>
        <div class="input-group">
          <label for="password">密码</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <button type="submit">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post('http://api.aizdc.com:8889/api/login', {
          username: this.username,
          password: this.password
        });

        if (response.data.success) {

          const { token, username, userID } = response.data;
          localStorage.setItem('token', token); 
          localStorage.setItem('username', username); 
          localStorage.setItem('userID', userID); 

          console.log(localStorage)

          // 登录成功，存储 token
          this.$store.dispatch('login', { token, username, userID });

          // 跳转到 home 页面
          this.$router.push({ path: '/home' });

        } else {
          // 登录失败，处理错误逻辑
          console.error('登录失败:', response.data.message);
          alert('用户名或密码错误');
        }
      } catch (error) {
        // 处理请求错误
        console.error('请求错误:', error);
        alert('登录请求失败，请稍后再试。');
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}



.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 30vw;
  height: 60vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.avatar {
  height: 12vh;
  width: 12vh;
  border-radius: 50%;
  background-image: url(../assets/jinmu.jpg);
  background-size: cover;
  background-position: center;
  margin-top: 5vh;
}

.input-group {
  margin-bottom: 15px;
  margin-top: 3vh;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 25px;
}

input {
  width: 25vb;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button {
  margin-top: 2vh;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>