<template>
    <h1 class="notFound">这个关于我们的页面还没有做哦，看看别的页面吧</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>