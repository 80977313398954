<template>
    <h1>Not Found 404</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>

 
