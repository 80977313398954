<template>
    <h1 class="notFound">这个入门文档的页面还没有做哦，看看别的页面吧</h1>
</template>

<script>
export default {

}
</script>

<style>
    .notFound {
        display: flex;
        margin-top:20px ;
    }
</style>