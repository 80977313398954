<template>

    <div class="hello">
        <div class="helloText">
            <img class="logo" src="../assets/jinmu.jpg">
            <p class="sayHi">你好,{{ username }}</p>
            <p class="help">点击左侧列表中需要的机器人助理，开始对话吧！</p>
            <div class="left"> ← </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'HeloComponent',
    components: {
    },
    computed: {
        username() {
            const name = this.$store.getters.username;
            console.log('用户名:', name); // 检查是否获取到用户名
            return name;
        }
    },

}
</script>

<style scoped>
.hello {
    padding-bottom: 20px;
    padding-left: 40px;
    font-size: 35px;
    height: 100%;
    width: 100%;
}

.helloText {
    background-color: white;
    height: 100%;
    border-radius: 7px;

}


.logo {
    margin-top: 20px;
    margin-left: 20px;
    width: 40px;
    border-radius: 50%;
}


p {
    margin-left: 50px;
}

.sayHi {
    margin-top: 0%;
    margin-bottom: 10px;
}

.help {
    margin-top: 0px;
    font-size: 22px;
}

.left {
    margin-top: 0px;
    margin-left: 50px;
    font-size: 100px;
}
</style>