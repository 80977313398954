<template>
  <div id="app">
    <router-view />
  </div>

</template>

<script>

export default {
  data() {
    return {

    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(90deg, rgba(227, 232, 216, 1) 0%, rgba(220, 242, 245, 1) 100%);
}

#app {
  width: 100%;
  height: 100%;

}
</style>
