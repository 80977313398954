import Layout from "@/views/Layout.vue";
import Home from "@/views/Home.vue";
import QuicklyStart from "@/views/QuicklyStart.vue";
import AboutUs from "@/views/AboutUs.vue";
import NotFound from "@/views/NotFound.vue";
import UserLogin from "@/views/UserLogin.vue";
import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";


const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes: [
    { path: '/login', component: UserLogin },
    {
      path: '/',
      component: Layout,
      redirect: '/home',
      children: [
        { path: '/home', component: Home, meta: { requiresAuth: true },},
        { path: '/quicklyStart', component: QuicklyStart },
        { path: '/aboutUs', component: AboutUs },
      ]
    },
    { path: '/:pathMatch(.*)*', component: NotFound },

  ]

})


// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  // 检查目标路由是否需要认证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = store.getters.token; // 从 Vuex store 中获取 token
    if (token) {
      next(); // 用户已登录，允许进入
    } else {
      next('/login'); // 用户未登录，重定向到登录页面
    }
  } else {
    next(); // 不需要认证的路由，直接放行
  }
});


export default router
