<template>
    <div class="announcement">
        <span class="announcement-title">《AI 聊天机器人网站上线公告》</span>
        <div class="announcement-content" ref="announcementContent">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;亲爱的用户们，我们的 AI 聊天机器人网站今日正式上线！它具备强大的语言理解和生成能力，能为你解答疑惑、激发创意、聊天陪伴。无论你有何种需求，都能在这里得到满足。我们致力于打造便捷、高效、有趣的交流平台，上线初期会持续优化。欢迎大家体验并反馈建议，快来开启精彩对话之旅吧！
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnnouncementComponent',
}

</script>



<style scoped>
.announcement {
    display: flex;
    flex-direction: column;
    /* 确保子元素垂直排列 */
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    width: 320px;
    margin-bottom: 20px;
}

.announcement-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    /* 增加间距以分隔标题和内容 */
}

.announcement-content {
    display: block;
    /* 确保内容是块级元素，可以换行 */
    font-size: 17px;
    margin-bottom: 10px;
}

</style>