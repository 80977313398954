<template>
    <h1>公告</h1>
    <AnnouncementComponent></AnnouncementComponent>
    
</template>

<script>
import AnnouncementComponent from './Announcement.vue';

export default {
    name: 'AnnouncementListComponent',
    components: {
        AnnouncementComponent,
    },

}
</script>

<style>
h1 {
    text-align: center;
}
</style>