<template>
    <h1>提示词模版</h1>

    <div class="MiaPrompt" v-if="MiaPrompt">
        <AnalogyGraphComponent />
        <DrawPromptComponent />
        <ModPicComponent />
    </div>
</template>

<script>
import DrawPromptComponent from './PromptDraw.vue';
import AnalogyGraphComponent from './PromptAnalogyGraph.vue';
import ModPicComponent from './PromptModPic.vue';
import emitter from '@/utils/EventBus';
import { ref, onMounted, onUnmounted } from 'vue';


export default {
    name: 'PromptListComponent',
    components: {
        DrawPromptComponent,
        AnalogyGraphComponent,
        ModPicComponent,

    },
    setup() {
    const MiaPrompt = ref(false);

    const handleMessage = (msg) => {
      // 根据消息内容控制 hello_vue 的显示
      if (msg === 'true') {
        MiaPrompt.value = true;
      }
      if (msg === 'false') {
        MiaPrompt.value = false;
      }

    };

    onMounted(() => {
      emitter.on('MiaPrompt', handleMessage);
    });

    onUnmounted(() => {
      emitter.off('MiaPrompt', handleMessage);
    });

    return { MiaPrompt };
  },

}
</script>

<style>
h1 {
    text-align: center;
    font-size: 40px;
}
</style>