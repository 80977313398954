<template>
    <div class="botlist">
        <OffenBotComponent />
        <CopywritingComponent />
        <ImageProcessingComponent />
        <AIshootComponent />
        <SoundSynthesisComponent />
        <MusicCreationComponent />
        <DigitalHumanComponent />
    </div>


</template>

<script>
import OffenBotComponent from './BotOffenBot.vue';
import CopywritingComponent from './BotCopywriting.vue';
import ImageProcessingComponent from './BotImageProcessing.vue';
import AIshootComponent from './BotAIshoot.vue';
import SoundSynthesisComponent from './BotSoundSynthesis.vue';
import MusicCreationComponent from './BotMusicCreation.vue';
import DigitalHumanComponent from './BotDigitalHuman.vue';

export default {
    name: 'BotListComponent',
    components: {
        OffenBotComponent,
        CopywritingComponent,
        ImageProcessingComponent,
        AIshootComponent,
        SoundSynthesisComponent,
        MusicCreationComponent,
        DigitalHumanComponent,

    },
    props: {

    },

};

</script>

<style scoped>

    .botlist {
        display: block;
    }


</style>